<script setup lang="ts">
import SpacyRowCenterComponent from '@/components/SpacyRowCenterComponent.vue'

const props = defineProps({
  step: Number
})
</script>

<template>
  <div class="center">
    <div class="form">
      <SpacyRowCenterComponent>
        <div v-if="props.step === 1">
          <img src="Mercury - baby.svg" alt="a colouful baby face" class="step activate" />
        </div>
        <div v-else>
          <router-link :to="{name: 'register_child'}">
            <img src="Mercury - baby.svg" alt="a gray scale baby face" class="step" />
          </router-link>
        </div>
        <div v-if="props.step === 2">
          <img src="Mercury - planning.svg" alt="a colouful calendar" class="step activate" />
        </div>
        <div v-else>
          <router-link :to="{name: 'register_planning'}">
            <img src="Mercury - planning.svg" alt="a gray scale calendar" class="step" />
          </router-link>
        </div>
        <div v-if="props.step === 3">
          <img src="Mercury - parent.svg" alt="a colouful parent face" class="step activate" />
        </div>
        <div v-else>
          <router-link :to="{name: 'register_parent'}">
            <img src="Mercury - parent.svg" alt="a gray scale parent face" class="step" />
          </router-link>
        </div>
        <div v-if="props.step === 4">
          <img src="Mercury - nursery.svg" alt="a colouful nursery building" class="step activate" />
        </div>
        <div v-else>
          <router-link :to="{name: 'register_nursery'}">
            <img src="Mercury - nursery.svg" alt="a gray scale nursery building" class="step" />
          </router-link>
        </div>
        <div v-if="props.step === 5">
          <img src="Mercury - summary.svg" alt="a colouful watching eye" class="step activate" />
        </div>
        <div v-else>
          <router-link :to="{name: 'register_summary'}">
            <img src="Mercury - summary.svg" alt="a gray scale  watching eye" class="step" />
          </router-link>
        </div>
      </SpacyRowCenterComponent>
    </div>
  </div>
</template>

<style>
.step {
  width: 4em;
  height: 4em;
  border-radius: 4em;
  box-shadow: var(--neu-icon);
}

.activate {
  box-shadow: var(--neu-icon);
  background: var(--highlight-background);
}
</style>
