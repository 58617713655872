import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import RegisterChildView from '@/views/RegisterChildView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: RegisterChildView
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import(/* webpackChunkName: "authentication" */ '@/views/AuthenticationView.vue')
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import(/* webpackChunkName: "signin" */ '@/views/SignInView.vue')
  },
  {
    path: '/eligibility',
    name: 'eligibility',
    component: () => import(/* webpackChunkName: "eligibility" */ '@/views/EligibilityView.vue')
  },
  {
    path: '/availability_form',
    name: 'availability_form',
    component: () => import(/* webpackChunkName: "availability_form" */ '@/views/AvailabilityFormView.vue')
  },
  {
    path: '/register_child',
    name: 'register_child',
    component: () => import(/* webpackChunkName: "register_child" */ '@/views/RegisterChildView.vue')
  },
  {
    path: '/register_planning',
    name: 'register_planning',
    component: () => import(/* webpackChunkName: "register_planning" */ '@/views/RegisterPlanningView.vue')
  },
  {
    path: '/register_parent',
    name: 'register_parent',
    component: () => import(/* webpackChunkName: "register_parent" */ '@/views/RegisterParentView.vue')
  },
  {
    path: '/register_nursery',
    name: 'register_nursery',
    component: () => import(/* webpackChunkName: "register_nursery" */ '@/views/RegisterNurseryView.vue')
  },
  {
    path: '/register_summary',
    name: 'register_summary',
    component: () => import(/* webpackChunkName: "register_summary" */ '@/views/RegisterSummaryView.vue')
  },
  {
    path: '/report',
    name: 'report',
    component: () => import(/* webpackChunkName: "report" */ '@/views/ReportView.vue')
  },
  {
    path: '/status',
    name: 'status',
    component: () => import(/* webpackChunkName: "status" */ '@/views/StatusView.vue')
  },
  {
    path: '/occupancy',
    name: 'occupancy',
    component: () => import(/* webpackChunkName: "occupancy" */ '@/views/OccupancyView.vue')
  },
  {
    path: '/nursery_spare_slot',
    name: 'nursery_spare_slot',
    component: () => import(/* webpackChunkName: "nursery_spare_slot" */ '@/views/NurserySpareSlotView.vue')
  },
  {
    path: '/preview_fees',
    name: 'preview_fees',
    component: () => import(/* webpackChunkName: "preview_fees" */ '@/views/PreviewFeesView.vue')
  },
  {
    path: '/preview_availability',
    name: 'preview_availability',
    component: () => import(/* webpackChunkName: "preview_availability" */ '@/views/PreviewAvailabilityView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
