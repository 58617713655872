<template>
  <div class="spacy_container">
    <slot />
  </div>
</template>

<style>
.spacy_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 1em;
  margin: 1em;
  width: 80%;
}
</style>
