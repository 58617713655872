<template>
  <div class="spacy_container">
    <slot />
  </div>
</template>

<style>
.spacy_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: left;
  gap: 1em;
  margin-top: 0.5em;
  width: 80%;

  /* Responsive Styles */
  @media (max-width: 768px) {

     display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: left;
  gap: 1em;
  margin-top: 0.5em;
  width: 80%;
  }
}
</style>
