
<template>
    <input type="checkbox" id="navbar-toggle" class="navbar-toggle" v-model="isActive">
    <label for="navbar-toggle" class="navbar-brand" onclick="toggleMenu">
      &#9776;
    </label>
  <nav id="header">
    <div v-if="isActive">
        <ul id="menu">
          <li>test 1</li>
          <li>test 2</li>
        </ul>
      </div>
    <SpacyRowCenterComponent>
      <SpacyItemComponent>
        <SpacyRowCenterComponent>
          <SpacyItemComponent>
            <img src="Eureka - logo.png" class="logo" alt="Le logo Eureka. Il représente un chiot en origami">
          </SpacyItemComponent>
          <SpacyItemComponent>
            <div class="logo_text">Eureka</div>
          </SpacyItemComponent>
        </SpacyRowCenterComponent>
      </SpacyItemComponent>
      <SpacyItemComponent>
        <h1 class="menu">Les crèches</h1>
      </SpacyItemComponent>
      <SpacyItemComponent>
        <h1 class="menu">Inscription</h1>
      </SpacyItemComponent>
      <SpacyItemComponent>
        <h1 class="menu growing">Suivi de dossier</h1>
      </SpacyItemComponent>
      <SpacyItemComponent>
        <div class="last">
          <button class="profile">
            <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="6" r="4" stroke="white" stroke-width="1.5" />
              <path
              d="M19.9975 18C20 17.8358 20 17.669 20 17.5C20 15.0147 16.4183 13 12 13C7.58172 13 4 15.0147 4 17.5C4 19.9853 4 22 12 22C14.231 22 15.8398 21.8433 17 21.5634"
              stroke="white" stroke-width="1.5" stroke-linecap="round" />
            </svg>
          </button>
        </div>
      </SpacyItemComponent>
    </SpacyRowCenterComponent>
  </nav>
</template>

<script setup lang="ts">
import SpacyItemComponent from './SpacyItemComponent.vue';
import SpacyRowCenterComponent from './SpacyRowCenterComponent.vue';

import { ref } from 'vue';

const isActive = ref(false);

const toggleMenu = () => {
  isActive.value = !isActive.value;
};
</script>








<style scoped>
@media screen and (min-width: 700px) {
#app {
  font-family: 'Arial', sans-serif;
}
.navbar-brand {
  display: none;
}
.navbar-toggle {
  display: none;
}

.navbar-brand {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 1rem;
  background-color: #f36f5a;
  color: #fff;
  align-items: center;
}

.navbar-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 4rem;
  right: 0;
  background-color: #f36f5a;
  padding: 1rem;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
}

.navbar-menu.is-active {
  transform: translateX(0);
}

.navbar-item {
  margin-bottom: 0.5rem;
  color: #fff;
  text-decoration: none;
}

.navbar-item:hover {
  text-decoration: underline;
}
}
/* Media query for mobile responsiveness */
@media screen and (max-width: 700px) {
      nav.is-active {
        transform: translateX(0);
      }

  .navbar-toggle {
      font-size: 1.5rem;
   display: none;
    position: fixed;
  }
   .navbar-brand {
      font-size: 2rem;
    top: 1rem;
    right: 1rem; /* Position the toggle icon on the right */
    z-index: 2;
  }

  .navbar-menu {
    top: 3rem; /* Adjust the top value as needed */
  }

  .navbar-menu,
  .navbar-menu.is-active {
    width: 100%;
  }
}
</style>

<style>
nav {
  margin: -1em 0 2em 0;
  padding: 0 0 1em 0;
  width: 100%;
  height: var(--navbar-height);
  background: var(--nav-background);
  color: var(--nav-font-color);
  box-shadow: var(--dark-shadow);
}

.logo {
  width: var(--logo-height);
  height: var(--logo-height);
  border-radius: var(--logo-height);
  margin: var(--logo-margin);
  box-shadow: var(--neu-icon);
}

.profile {
  box-shadow: var(--neu-icon);
  border-radius: var(--logo-height);
  background-color: var(--nav-background);
}

.logo_text {
  font-family: "logo-font";
  font-style: bold;
  font-size: x-large;
  margin-left: -1em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.menu {
  margin-top: 0;
  text-align: center;
}

.last {
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
  padding-right: 1em;
}
/* Slicknav Styling for Mobile */
@media (max-width: 700px) {
  /* Add slicknav-specific styles here */
  nav {
    display: none; /* Hide the regular nav for mobile */
  }
}

</style>
