<template>
  <div class="spacy_item">
    <slot />
  </div>
</template>

<style>
.spacy_item {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 1em; /* Add some padding for better spacing */
  font-size: 14px; /* Default font size */

  /* Responsive Styles */
  @media (max-width: 768px) {
    flex-basis: 100%; /* Full width on smaller screens */
    margin-bottom: 1em; /* Add spacing between items on smaller screens */
    padding-right: 0em;
    padding-bottom: 1em;
    font-size: 12px; /* Adjust font size for smaller screens */
  }
}
</style>
